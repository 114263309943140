import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'

export function sliderImages() {
	const hasElement = document.getElementsByClassName('swiper-slider-images')

	if (hasElement.length > 0) {
		const swiper = new Swiper('.swiper-slider-images', {
			modules: [Pagination],
			loop: false,
			slidesPerView: 1,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
		})
	}
}
