import Swiper from 'swiper'
import { FreeMode, Scrollbar } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'

export function sliderGallery() {
	const hasElement = document.getElementsByClassName('swiper-slider-gallery')
	const billboardElement = document.getElementsByClassName(
		'swiper-billboard-slider-gallery'
	)

	if (hasElement.length > 0) {
		const swiper = new Swiper('.swiper-slider-gallery', {
			modules: [FreeMode, Scrollbar],
			autoHeight: false,
			loop: false,
			grabCursor: true,
			slidesPerView: 'auto',
			spaceBetween: 10,
			freeMode: {
				enabled: true,
				sticky: true,
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				hide: false,
				draggable: true,
			},
		})
	}

	if (billboardElement.length > 0) {
		const swiper = new Swiper('.swiper-billboard-slider-gallery', {
			modules: [FreeMode],
			centeredSlides: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			autoHeight: false,
			loop: true,
			grabCursor: true,
			slidesPerView: 'auto',
			spaceBetween: 10,
			freeMode: {
				enabled: true,
				sticky: true,
			},
		})
	}
}
